export interface CSVHeader {
  key: string
  title: string
}

export const convertCSVToJSON = (csv: string): {json: Record<string, string>[], headers: CSVHeader[]} => {
  const rows = csv.trim().split('\n')
  const headers = rows[0].split(',')
    .map((header) => {
      return {
        key: header.trim().replaceAll('"', '').replaceAll(' ', ''),
        title: header.trim().replaceAll('"', '')
      }
    })
  const json = rows.slice(1).map((row) => {
    const values = row.split('","')
    const obj: Record<string, string> = {}
    headers.forEach((header, index) => {
      obj[header.key] = values[index]?.trim().replaceAll('"', '') ?? ''
    })
    return obj
  })

  return { headers, json }
}
