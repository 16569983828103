import * as analytics from '@icepanel/app-analytics'

import { diagramFlowId, diagramTheme, diagramType } from '@/modules/diagram/helpers/analytics-properties'
import { landscapeId } from '@/modules/landscape/helpers/analytics-group'
import { organizationId } from '@/modules/organization/helpers/analytics-group'
import { versionLatest, versionModelId, versionModelType } from '@/modules/version/helpers/analytics-properties'

import { shareDiagramExportImageFileType, shareLinkAuthProvider, shareLinkFrameLocation, shareLinkInFrame, shareLinkProtected, shareLinkShortId } from './analytics-properties'

/**
 * Screens
 */

export const shareLinkDiagramScreen = new analytics.Event({
  displayName: 'Share link diagram screen',
  name: 'share_link_diagram_screen',
  tags: [analytics.Tags.Screen]
}, {
  diagramType,
  landscapeId,
  organizationId,
  shareLinkFrameLocation,
  shareLinkInFrame,
  shareLinkShortId,
  versionLatest,
  versionModelId,
  versionModelType
})
export const shareLinkLoginScreen = new analytics.Event({
  displayName: 'Share link login screen',
  name: 'share_link_login_screen',
  tags: [analytics.Tags.Screen]
}, {
  shareLinkAuthProvider,
  shareLinkFrameLocation,
  shareLinkInFrame,
  shareLinkProtected,
  shareLinkShortId
})
export const shareLinkNotFoundScreen = new analytics.Event({
  displayName: 'Share link not found screen',
  name: 'share_link_not_found_screen',
  tags: [analytics.Tags.Screen]
}, {
  shareLinkFrameLocation,
  shareLinkInFrame,
  shareLinkShortId
})
export const shareEmbedScreen = new analytics.Event({
  displayName: 'Share embed screen',
  name: 'share_embed_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId,
  shareLinkFrameLocation: {
    ...shareLinkFrameLocation,
    required: false
  },
  versionLatest,
  versionModelId,
  versionModelType
})
export const shareLinkScreen = new analytics.Event({
  displayName: 'Share link screen',
  name: 'share_link_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId,
  versionLatest,
  versionModelId,
  versionModelType
})
export const shareExportScreen = new analytics.Event({
  displayName: 'Share export screen',
  name: 'share_export_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId,
  versionLatest,
  versionModelId,
  versionModelType
})

/**
 * Dialogs
 */

export const shareDialog = new analytics.Event({
  displayName: 'Share dialog',
  name: 'share_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  landscapeId,
  organizationId,
  versionLatest,
  versionModelId,
  versionModelType
})
export const shareBlankDialog = new analytics.Event({
  displayName: 'Share blank dialog',
  name: 'share_blank_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  landscapeId,
  organizationId,
  versionLatest,
  versionModelId,
  versionModelType
})

/**
 * Links
 */

export const shareLinkIcePanelLink = new analytics.Event({
  displayName: 'Share link IcePanel link',
  name: 'share_link_icepanel_link',
  tags: [analytics.Tags.Link]
}, {
  landscapeId,
  organizationId,
  shareLinkShortId
})
export const shareLinkDesignedInIcePanelLink = new analytics.Event({
  displayName: 'Share link designed in IcePanel link',
  name: 'share_link_designed_in_icepanel_link',
  tags: [analytics.Tags.Link]
}, {
  landscapeId,
  organizationId,
  shareLinkShortId
})
export const shareLinkGetStartedLink = new analytics.Event({
  displayName: 'Share link get started link',
  name: 'share_link_get_started_link',
  tags: [analytics.Tags.Link]
}, {
  landscapeId,
  organizationId,
  shareLinkShortId
})

/**
 * Events
 */

export const shareLinkCreate = new analytics.Event({
  displayName: 'Share link create',
  name: 'share_link_create'
}, {
  landscapeId,
  organizationId,
  shareLinkProtected,
  versionLatest,
  versionModelId,
  versionModelType
})
export const shareLinkUpdate = new analytics.Event({
  displayName: 'Share link delete',
  name: 'share_link_delete'
}, {
  landscapeId,
  organizationId,
  shareLinkProtected: {
    ...shareLinkProtected,
    required: false
  },
  versionLatest,
  versionModelId,
  versionModelType
})
export const shareLinkDelete = new analytics.Event({
  displayName: 'Share link delete',
  name: 'share_link_delete'
}, {
  landscapeId,
  organizationId,
  shareLinkProtected,
  versionLatest,
  versionModelId,
  versionModelType
})
export const shareLinkLogin = new analytics.Event({
  displayName: 'Share link login',
  name: 'share_link_login'
}, {
  landscapeId,
  organizationId,
  shareLinkAuthProvider,
  shareLinkFrameLocation,
  shareLinkInFrame,
  shareLinkProtected,
  shareLinkShortId,
  versionLatest,
  versionModelId
})
export const shareDiagramExportImage = new analytics.Event({
  displayName: 'Share diagram export image',
  name: 'share_diagram_export_image'
}, {
  diagramFlowId: {
    ...diagramFlowId,
    required: false
  },
  diagramTheme,
  diagramType,
  landscapeId,
  organizationId,
  shareDiagramExportImageFileType,
  shareLinkShortId,
  versionLatest,
  versionModelId,
  versionModelType
})
export const shareExportPdf = new analytics.Event({
  displayName: 'Share export PDF',
  name: 'share_export_pdf'
}, {
  landscapeId,
  organizationId,
  shareLinkShortId,
  versionLatest,
  versionModelId,
  versionModelType
})
export const shareExportJson = new analytics.Event({
  displayName: 'Share export JSON',
  name: 'share_export_json'
}, {
  landscapeId,
  organizationId,
  shareLinkShortId,
  versionLatest
})
export const shareExportCsvObjects = new analytics.Event({
  displayName: 'Share export CSV objects',
  name: 'share_export_csv_objects'
}, {
  landscapeId,
  organizationId,
  shareLinkShortId,
  versionLatest
})
export const shareExportCsvConnections = new analytics.Event({
  displayName: 'Share export CSV connections',
  name: 'share_export_csv_connections'
}, {
  landscapeId,
  organizationId,
  shareLinkShortId,
  versionLatest
})
export const shareLinkCopyClipboard = new analytics.Event({
  displayName: 'Share link copy clipboard',
  name: 'share_link_copy_clipboard'
}, {
  landscapeId,
  organizationId,
  versionLatest,
  versionModelId,
  versionModelType
})
export const shareEmbedCopyClipboard = new analytics.Event({
  displayName: 'Share embed copy clipboard',
  name: 'share_embed_copy_clipboard'
}, {
  landscapeId,
  organizationId,
  shareLinkFrameLocation,
  versionLatest,
  versionModelId,
  versionModelType
})
export const shareLinkDrawerExpand = new analytics.Event({
  displayName: 'Share link drawer expand',
  name: 'share_link_drawer_expand'
}, {
  diagramType,
  landscapeId,
  organizationId,
  shareLinkFrameLocation,
  shareLinkInFrame,
  shareLinkShortId,
  versionLatest,
  versionModelId,
  versionModelType
})
export const shareLinkDrawerCollapse = new analytics.Event({
  displayName: 'Share link drawer collapse',
  name: 'share_link_drawer_collapse'
}, {
  diagramType,
  landscapeId,
  organizationId,
  shareLinkFrameLocation,
  shareLinkInFrame,
  shareLinkShortId,
  versionLatest,
  versionModelId,
  versionModelType
})

/**
 * Hidden
 */

export const shareLinkModelScreenHidden = new analytics.Event({
  displayName: 'Share link model screen',
  hidden: true,
  name: 'share_link_model_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId,
  shareLinkShortId,
  versionLatest,
  versionModelId,
  versionModelType
})
export const shareLinkLandscapeScreenHidden = new analytics.Event({
  displayName: 'Share link landscape screen',
  hidden: true,
  name: 'share_link_landscape_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId,
  shareLinkFrameLocation,
  shareLinkInFrame,
  shareLinkShortId
})
export const shareModelScreenHidden = new analytics.Event({
  displayName: 'Share model screen',
  hidden: true,
  name: 'share_model_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId,
  versionLatest,
  versionModelId,
  versionModelType
})
export const shareLinkCreateInteractiveDiagramsLinkHidden = new analytics.Event({
  displayName: 'Share link create interactive diagrams link',
  hidden: true,
  name: 'share_link_create_interactive_diagrams_link',
  tags: [analytics.Tags.Link]
}, {
  landscapeId,
  organizationId,
  shareLinkShortId
})
export const shareLinkDrawerObjectExpandHidden = new analytics.Event({
  displayName: 'Share link drawer object expand',
  hidden: true,
  name: 'share_link_drawer_object_expand'
}, {
  diagramType,
  landscapeId,
  organizationId,
  shareLinkFrameLocation,
  shareLinkInFrame,
  shareLinkShortId,
  versionLatest,
  versionModelId,
  versionModelType
})
export const shareLinkDrawerObjectCollapseHidden = new analytics.Event({
  displayName: 'Share link drawer object collapse',
  hidden: true,
  name: 'share_link_drawer_object_collapse'
}, {
  diagramType,
  landscapeId,
  organizationId,
  shareLinkFrameLocation,
  shareLinkInFrame,
  shareLinkShortId,
  versionLatest,
  versionModelId,
  versionModelType
})
export const shareExportPngHidden = new analytics.Event({
  displayName: 'Share export PNG',
  hidden: true,
  name: 'share_export_png'
}, {
  diagramFlowId: {
    ...diagramFlowId,
    required: false
  },
  diagramTheme,
  diagramType,
  landscapeId,
  organizationId,
  shareLinkShortId,
  versionLatest,
  versionModelId,
  versionModelType
})
